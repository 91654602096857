import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
	return (
		<Layout>
			<SEO
				title="HTTP 404 Seite nicht gefunden"
			/>
			<section>
				<h1>HTTP 404 Seite nicht gefunden</h1>
				<p>Der klassische Auslöser für einen Error 404 liegt vor, wenn Webseiteninhalte entfernt oder auf eine andere URL verlegt wurden.</p>
			
			</section>
		</Layout>
	);
}

export default IndexPage;
